<template>
	<div>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6">
					<div>Adresse</div>
					<v-divider class="my-4"></v-divider>
					<v-select
						v-model="orderPoint.address.country"
						:items="countries"
						item-text="name"
						item-value="iso"
						:label="$t('address.country')"
						return-object
						outlined
						class="border-shaped"
					>
						<template #selection="{ item }">
							<v-list-item-icon>
								<CountryFlag :country="item.iso" rounded class="mt-n2" />
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $i18n.locale === 'fr' ? item.name : item.en_name }}</v-list-item-title>
							</v-list-item-content>
						</template>
						<template #item="{ item }">
							<v-list-item-icon>
								<CountryFlag :country="item.iso" rounded class="mt-n2" />
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $i18n.locale === 'fr' ? item.name : item.en_name }}</v-list-item-title>
							</v-list-item-content>
						</template>
					</v-select>
					<v-select
						v-model="orderPoint.address.address_type"
						:label="$t('address.type')"
						outlined
						class="border-shaped"
						return-object
						:items="address_types_items"
						:item-text="(item) => ($i18n.locale === 'fr' ? item.name : item.en_name)"
						required
						:rules="address_typesRules"
					></v-select>
				</v-col>
				<v-divider vertical></v-divider>
				<v-col cols="12" md="6">
					<div>Produits</div>
					<v-divider class="my-4"></v-divider>
					<v-data-table
						:items="orderPoint.lines"
						:headers="[
							{ text: 'Titre', value: 'product.title' },
							{ text: 'Nombre de pages', value: 'product.page_count' },
							{ text: 'Quantité', value: 'quantity' },
							{ text: 'action', value: 'action' },
						]"
						hide-default-footer
						dense
						show-expand
						single-expand
						:expanded.sync="expanded"
						item-key="uuid"
					>
						<template #no-data>
							<div class="font-italic text--secondary">Aucun produit</div>
						</template>
						<template #item.action="{ item }">
							<v-btn icon @click="orderPoint.lines.splice(orderPoint.lines.indexOf(item), 1)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</template>
						<template #item.quantity="{ item }">
							<v-edit-dialog :return-value="item.quantity">
								<v-icon class="text-body-2">mdi-pencil</v-icon> {{ item.quantity }}
								<template #input>
									<v-text-field
										v-model="item.quantity"
										label="Quantité"
										type="number"
										outlined
										class="border-shaped mt-4"
										autofocus
									></v-text-field>
								</template>
							</v-edit-dialog>
						</template>
						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length">
								<v-card-text class="text-caption">
									<div>{{ $t('product.title') }} : {{ item.product.title }}</div>
									<div>{{ $t('product.page_count') }} : {{ item.product.page_count }}</div>
									<div>{{ $t('product.finition') }} : {{ item.product.finition?.name }}</div>
									<div>{{ $t('product.impression') }} : {{ item.product.impression?.name }}</div>
									<div>{{ $t('product.format') }} : {{ item.product.format?.name }}</div>
									<div>{{ $t('product.category') }} : {{ item.product.category?.name }}</div>
									<div>{{ $t('product.inner_paper') }} : {{ item.product.papier_interieur?.papier.name }}</div>
									<div>{{ $t('product.cover_paper') }} : {{ item.product.papier_couverture?.papier.name }}</div>
									<div>{{ $t('product.lamination') }} : {{ item.product.pelliculage?.name }}</div>
									<div>{{ $t('product.recto_verso') }} : {{ item.product.recto_verso ? 'Oui' : 'Non' }}</div>
									<div>{{ $t('product.lost_edges') }} : {{ item.product.bords_perdus ? 'Oui' : 'Non' }}</div>
								</v-card-text>
							</td>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card-text>
	</div>
</template>

<script>
	import axios from 'axios';
	import ProductForm from './ProductForm.vue';

	export default {
		name: 'OrderPointCard',
		props: {
			orderPoint: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				countries: [],
				expanded: [],
				address_types_items: [],
				address_typesRules: [(v) => v !== null || this.$t('form.required.address.type')],
			};
		},
		components: { ProductForm },
		mounted() {
			this.getCountries();
			this.getAddressType();
		},
		methods: {
			getCountries() {
				axios
					.get(process.env.VUE_APP_API_URL + '/country', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.countries = response.data.countries;
						!this.orderPoint.address.country ? (this.orderPoint.address.country = this.countries[0]) : null;
					});
			},
			getAddressType() {
				axios
					.get(process.env.VUE_APP_API_URL + '/address_type', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.address_types_items = r.data.address_types;
						!this.orderPoint.address.address_type
							? (this.orderPoint.address.address_type = this.address_types_items[0])
							: null;
					})
					.catch(() => {
						EventBus.$emit('notif', this.$t('global.error'), 'error');
					});
			},
		},
	};
</script>

<style scoped lang="scss"></style>

<i18n lang="json">
{
	"fr": {
		"add_product": "Ajouter un produit"
	},
	"en": {
		"add_product": "Add a product"
	}
}
</i18n>
