<template>
	<section class="panier">
		<v-divider></v-divider>
		<div v-if="loading_panier" class="text-center">
			<v-progress-circular :size="50" color="yellow" indeterminate class="my-10"></v-progress-circular>
		</div>
		<v-card v-else elevation="0">
			<v-list v-if="panier">
				<template v-for="(line, index) in panier.lignes_commande">
					<v-list-item v-if="line.enabled && line.product.enabled" :key="index">
						<v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" rounded size="80">
							<VignetteFetcher :id="line.product.id" />
						</v-list-item-avatar>

						<v-list-item-content class="text-left">
							<v-list-item-title>{{ line.product.title }}</v-list-item-title>
							<v-list-item-subtitle>{{ line.product.page_count }} {{ $t('product.pages') }}</v-list-item-subtitle>
							<v-list-item-subtitle>
								{{ line.address.address_full }}
								{{ line.address.country.name }}
							</v-list-item-subtitle>
							<v-list-item-subtitle>
								<strong>{{ $t('address.contact') }}</strong> :
								{{ line.address.contact }}
							</v-list-item-subtitle>
							<v-list-item-subtitle v-if="line.address.raison_sociale">
								<strong>{{ $t('address.raison_sociale') }}</strong> :
								{{ line.address.raison_sociale }}
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-list-item-action-text>{{ $t('order.line.quantity') }} : {{ line.quantity }}</v-list-item-action-text>
							<OrderProductEdit :line="line" />
							<v-btn small class="border-shaped" color="red" depressed @click="deleteLine(line.id)">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
				</template>
			</v-list>
			<v-list v-if="panier" dense>
				<v-divider class="mt-n3"></v-divider>
				<v-list-item>
					<v-list-item-title>{{ $t('order.price.production') }} :</v-list-item-title>
					<v-list-item-action>
						<v-list-item-action-text> {{ panier.prix_fabrication.toFixed(2) }}€ </v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
				<v-list-item class="mt-n5">
					<v-list-item-title>{{ $t('order.price.packaging') }} :</v-list-item-title>
					<v-list-item-action>
						<v-list-item-action-text>{{ panier.prix_emballage.toFixed(2) }}€</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
				<v-list-item class="mt-n5">
					<v-list-item-title>{{ $t('order.price.shipping') }} :</v-list-item-title>
					<v-list-item-action>
						<v-list-item-action-text>{{ panier.prix_livraison.toFixed(2) }}€</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
				<v-list-item class="mt-n5">
					<v-list-item-title>{{ $t('order.price.ht') }} :</v-list-item-title>
					<v-list-item-action>
						<v-list-item-action-text>{{ panier.prix_ht.toFixed(2) }}€</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
				<v-list-item class="mt-n5">
					<v-list-item-title>{{ $t('order.price.ttc') }} :</v-list-item-title>
					<v-list-item-action>
						<v-list-item-action-text>{{ panier.prix_ttc.toFixed(2) }}€</v-list-item-action-text>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<v-list v-else class="px-1">
				<v-alert type="error" outlined class="border-shaped">{{ $t('empty_cart') }}</v-alert>
			</v-list>
			<v-card-actions v-if="panier" class="mt-n3">
				<v-btn class="ml-auto border-shaped yellow--bg" depressed :loading="loading" @click="confirmDialog = true">
					<v-icon>mdi-check</v-icon>
				</v-btn>
				<v-btn class="border-shaped" color="red" depressed @click="deletePanier">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-lazy transition="slide-y-transition">
			<v-card v-if="errors" class="mt-2 mr-2" elevation="0">
				<v-card-text>
					<v-list dense>
						<v-subheader>{{ $t('global.errors') }} :</v-subheader>
						<v-list-item v-for="(e, i) in errors" :key="i" class="red--text text-justify">
							<v-list-item-icon>
								<v-icon class="red--text">mdi-alert-circle</v-icon>
							</v-list-item-icon>
							<v-list-item-content>{{ e }}</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-lazy>
		<v-dialog v-model="confirmDialog" scrollable persistent max-width="500px" transition="dialog-transition">
			<v-card shaped elevation="24">
				<v-card-title class="text-h6">
					{{ $t('are_you_sure') }}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="ml-auto border-shaped yellow--bg" depressed :loading="loading" @click="validatePanier">
						<v-icon>mdi-check</v-icon>
					</v-btn>
					<v-btn class="border-shaped" color="red" depressed @click="confirmDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</section>
</template>

<script lang="js">
	import axios from 'axios';
	import OrderProductEdit from '@/components/order/OrderProductEdit';
	import VignetteFetcher from '@/components/order/VignetteFetcher';
	import { EventBus } from '@/utils';

	export default {
	    name: 'Panier',
	    components: { OrderProductEdit, VignetteFetcher },
	    props: [],
	    data() {
	        return {
	            panier: null,
	            errors: null,
	            loading: false,
	            loading_panier: true,
	            confirmDialog: false,
	            apiUrl: process.env.VUE_APP_API_URL,
	        };
	    },
	    computed: {},
	    mounted() {
	        EventBus.$on('refreshPanier', () => {
	            this.getPanier();
	        });
	    },
	    methods: {
	        getPanier() {
	            this.loading_panier = true;
	            if (this.$store.getters.isAuthenticated && !this.$store.getters.isChild) {
	                axios
	                    .get(process.env.VUE_APP_API_URL + '/panier', {
	                        headers: {
	                            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                        },
	                    })
	                    .then((response) => {
	                        this.panier = response.data.panier;
	                    })
	                    .catch(() => {
	                        EventBus.$emit('notif', this.$t('cart_error'), 'error');
	                    })
	                    .finally(() => {
	                        this.loading_panier = false;
	                    });
	            }
	        },
	        deleteLine(id) {
	            this.loading = true;
	            axios
	                .delete(process.env.VUE_APP_API_URL + '/order/line/' + id, {
	                    headers: {
	                        Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                    },
	                })
	                .then(() => {
	                    EventBus.$emit('refreshPanier');
	                    EventBus.$emit('refreshProducts');
	                    EventBus.$emit('refreshOrders');
	                    EventBus.$emit('notif', this.$t('product_deleted'), 'error');
	                })
	                .catch(() => {
	                    EventBus.$emit('refreshPanier');
	                    EventBus.$emit('refreshProducts');
	                    EventBus.$emit('refreshOrders');
	                    EventBus.$emit('notif', this.$t('global.error'), 'error');
	                })
	                .finally(() => {
	                    this.loading = false;
	                });
	        },
	        deletePanier() {
	            this.errors = null;
	            if (this.panier) {
	                this.loading = true;
	                axios
	                    .delete(process.env.VUE_APP_API_URL + '/panier', {
	                        headers: {
	                            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                        },
	                    })
	                    .then((response) => {
	                        if (response.data.delete) {
	                            EventBus.$emit('refreshPanier');
	                            EventBus.$emit('refreshProducts');
	                            EventBus.$emit('refreshOrders');
	                            EventBus.$emit('notif', this.$t('cart_deleted'), 'success');
	                        }
	                    })
	                    .catch(() => {
	                        EventBus.$emit('notif', this.$t('global.error'), 'error');
	                    })
	                    .finally(() => {
	                        this.loading = false;
	                    });
	            }
	        },
	        validatePanier() {
	            this.errors = null;
	            if (this.panier) {
	                this.loading = true;
	                axios
	                    .patch(process.env.VUE_APP_API_URL + '/panier', null, {
	                        headers: {
	                            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                        },
	                    })
	                    .then(() => {
	                        EventBus.$emit('refreshPanier');
	                        EventBus.$emit('refreshProducts');
	                        EventBus.$emit('refreshOrders');
	                        this.$router.push({ name: 'Orders' });
	                        EventBus.$emit('notif', this.$t('cart_validated'), 'success');
	                    })
	                    .catch((e) => {
	                        this.errors = e.response.data.errors;
	                        EventBus.$emit('notif', this.$t('global.error'), 'error');
	                    })
	                    .finally(() => {
	                        this.loading = false;
	                    });
	            }
	        },
	    },
	};
</script>

<i18n lang="json">
{
	"fr": {
		"cart": "Panier",
		"empty_cart": "Panier Vide",
		"cart_error": "Erreur lors de la récupération du panier",
		"are_you_sure": "Êtes-vous sûr de vouloir passer commande ?",
		"cart_deleted": "Panier supprimé",
		"cart_validated": "Panier validé",
		"product_deleted": "Produit supprimé du panier"
	},
	"en": {
		"cart": "Cart",
		"empty_cart": "Empty Cart",
		"cart_error": "Error retrieving cart",
		"are_you_sure": "Are you sure you want to proceed ?",
		"cart_deleted": "Cart deleted",
		"cart_validated": "Cart validated",
		"product_deleted": "Product deleted from cart"
	}
}
</i18n>
