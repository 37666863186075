<template>
	<section class="simulation">
		<page-title :title="$t('title')" :breadcrumbs="items"></page-title>
		<!-- dialog des prix -->
		<v-dialog v-model="dialog" max-width="800" class="border-shaped">
			<v-sheet class="border-shaped" elevation="24">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h5 text-lg-h4">
							{{ $t('title') }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="mb-4"></v-divider>
				<v-list dense color="transparent">
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.production') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>
								{{ prix_fabrication ? prix_fabrication.toFixed(2) + '€' : $t('unavailable') }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.packaging') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>
								{{ prix_emballage ? prix_emballage.toFixed(2) + '€' : $t('unavailable') }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.shipping') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>
								{{ prix_livraison ? prix_livraison.toFixed(2) + '€' : $t('unavailable') }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.ht') }} :</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>{{ prix_ht.toFixed(2) }}€</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-n5">
						<v-list-item-title>{{ $t('order.price.ttc') }}:</v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>{{ prix_ttc.toFixed(2) }}€</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
					<v-list-item class="mt-5">
						<v-list-item-title></v-list-item-title>
						<v-list-item-action>
							<v-list-item-action-text>
								<v-btn depressed class="yellow--bg border-shaped" @click="save">
									<v-icon>mdi-content-save-outline</v-icon>
								</v-btn>
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-sheet>
		</v-dialog>
		<!-- dialog des historiques -->
		<v-dialog v-model="showHistory" scrollable :overlay="false" max-width="1000" transition="dialog-transition">
			<v-sheet class="border-shaped" elevation="24">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h5 text-lg-h4"> Historique </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="mb-4"></v-divider>
				<v-data-table
					:headers="historyHeaders"
					:items="history"
					dense
					show-expand
					single-expand
					:expanded.sync="historyExpanded"
					item-key="uuid"
				>
					<template #item.date="{ item }">
						{{ new Date(item.date).toLocaleString() }}
					</template>
					<template #item.orderPoints="{ item }">
						{{ item.orderPoints.length }}
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<v-card-text v-for="orderPoint in item.orderPoints" :key="orderPoint.uuid">
								<v-row>
									<v-col cols="12" md="6">
										<div>Adresse</div>
										<v-divider class="my-1"></v-divider>
										<div>
											{{ $t('address.country') }} :
											{{ $i18n.locale === 'fr' ? orderPoint.address.country.name : orderPoint.address.country.en_name }}
										</div>
										<div>
											{{ $t('address.type') }} :
											{{ $i18n.locale === 'fr' ? orderPoint.address.address_type.name : orderPoint.address.address_type.en_name }}
										</div>
									</v-col>
									<v-divider vertical></v-divider>
									<v-col cols="12" md="6">
										<div>Produits</div>
										<v-divider class="my-1"></v-divider>
										<v-data-table
											:items="orderPoint.lines"
											:headers="[
												{ text: 'Titre', value: 'product.title' },
												{ text: 'Nombre de pages', value: 'product.page_count' },
												{ text: 'Quantité', value: 'quantity' },
											]"
											hide-default-footer
											dense
											show-expand
											single-expand
											:expanded.sync="productExpanded"
											item-key="uuid"
										>
											<template #no-data>
												<div class="font-italic text--secondary">Aucun produit</div>
											</template>
											<template v-slot:expanded-item="{ headers, item }">
												<td :colspan="headers.length">
													<v-card-text class="text-caption">
														<div>{{ $t('product.title') }} : {{ item.product.title }}</div>
														<div>{{ $t('product.page_count') }} : {{ item.product.page_count }}</div>
														<div>{{ $t('product.finition') }} : {{ item.product.finition?.name }}</div>
														<div>{{ $t('product.impression') }} : {{ item.product.impression?.name }}</div>
														<div>{{ $t('product.format') }} : {{ item.product.format?.name }}</div>
														<div>{{ $t('product.category') }} : {{ item.product.category?.name }}</div>
														<div>{{ $t('product.inner_paper') }} : {{ item.product.papier_interieur?.papier.name }}</div>
														<div>{{ $t('product.cover_paper') }} : {{ item.product.papier_couverture?.papier.name }}</div>
														<div>{{ $t('product.lamination') }} : {{ item.product.pelliculage?.name }}</div>
														<div>{{ $t('product.recto_verso') }} : {{ item.product.recto_verso ? 'Oui' : 'Non' }}</div>
														<div>{{ $t('product.lost_edges') }} : {{ item.product.bords_perdus ? 'Oui' : 'Non' }}</div>
													</v-card-text>
												</td>
											</template>
										</v-data-table>
									</v-col>
								</v-row>
							</v-card-text>
						</td>
					</template>
				</v-data-table>
			</v-sheet>
		</v-dialog>
		<v-container>
			<div class="d-flex align-center gap-2">
				<v-menu offset-y open-on-hover>
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed class="yellow--bg border-shaped" v-bind="attrs" v-on="on">
							<v-icon>mdi-information-outline</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item> Les produits et l'historique sont stockés localement dans votre navigateur </v-list-item>
					</v-list>
				</v-menu>

				<v-btn depressed class="yellow--bg border-shaped" @click="showHistory = true"><v-icon>mdi-history</v-icon></v-btn>
			</div>
			<div class="d-flex align-center">
				Bibliothèque
				<v-spacer></v-spacer>
				<v-btn large class="yellow--bg border-shaped mb-4" depressed @click="addProduct">
					<v-icon>mdi-plus</v-icon>
					Ajouter un produit
				</v-btn>
			</div>
			<v-divider class="mb-4"></v-divider>
			<v-data-iterator content-tag="tag" :items="products" :footer-props="footerProps" :items-per-page="itemsPerPage">
				<template #no-data>
					<div class="font-italic text--secondary">Aucun produit</div>
				</template>
				<template #default="{ items }">
					<v-row>
						<v-col cols="12" sm="6" md="4" xl="3" v-for="product in items" :key="product.uuid">
							<v-lazy transition="slide-y-transition">
								<v-card shaped>
									<v-card-title>
										<span v-if="product.title">{{ product.title }}</span>
										<span v-else>Produit n° {{ products.indexOf(product) + 1 }}</span>
										<v-spacer></v-spacer>
										<v-btn icon @click="products.splice(products.indexOf(product), 1)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</v-card-title>
									<ProductForm :product="product"></ProductForm>
									<v-card-actions class="pt-0">
										<v-spacer></v-spacer>
										<OrderProduct :product="product" :orderPoints="orderPoints" class="mr-1"></OrderProduct>
										<v-btn depressed class="yellow--bg border-shaped" @click="product.dialog = true" :disabled="product.dialog">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-lazy>
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
			<div class="d-flex align-center mt-4">
				Panier
				<v-spacer></v-spacer>
				<v-btn large class="yellow--bg border-shaped mb-4" depressed @click="addOrderPoint">
					<v-icon>mdi-plus</v-icon>
					Ajouter un point de livraison
				</v-btn>
			</div>
			<v-divider class="mb-4"></v-divider>
			<v-data-iterator content-tag="tag" :items="orderPoints" :items-per-page="-1" hide-default-footer>
				<template #no-data> <div class="font-italic text--secondary">Aucun point de livraison</div> </template>
				<template #default="{ items }">
					<v-row>
						<v-col cols="12" v-for="orderPoint in items" :key="orderPoint.uuid">
							<v-card shaped>
								<v-card-title>
									Point de livraison n° {{ orderPoints.indexOf(orderPoint) + 1 }}
									<v-spacer></v-spacer>
									<v-btn icon @click="orderPoints.splice(orderPoints.indexOf(orderPoint), 1)">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-card-title>
								<OrderPointCard :orderPoint="orderPoint"></OrderPointCard>
							</v-card>
						</v-col>
					</v-row>
				</template>
			</v-data-iterator>
			<div>
				<v-btn
					large
					block
					class="yellow--bg border-shaped mt-4"
					depressed
					@click="calculatePrice"
					:disabled="!isCartSendable || loading"
					:loading="loading"
				>
					<v-icon>mdi-calculator</v-icon>
					Calculer le prix
				</v-btn>
			</div>
		</v-container>
	</section>
</template>

<script>
	import axios from 'axios';
	import PageTitle from '@/components/info/PageTitle';
	import ProductForm from '@/components/simulation/ProductForm.vue';
	import OrderPointCard from '@/components/simulation/OrderPointCard.vue';
	import OrderProduct from '@/components/simulation/OrderProduct.vue';
	import { nanoid } from 'nanoid';

	export default {
		name: 'SimulationTest',
		metaInfo: {
			title: 'Rapidbook - Simulation Test',
		},
		components: { PageTitle, ProductForm, OrderPointCard, OrderProduct },
		props: [],
		data() {
			return {
				dialog: false,
				loading: false,
				prix_emballage: 0,
				prix_fabrication: 0,
				prix_livraison: 0,
				prix_ht: 0,
				prix_ttc: 0,
				products: JSON.parse(localStorage.getItem('rapidbook-simu-products')) || [],
				showHistory: false,
				historyExpanded: [],
				productExpanded: [],
				history: JSON.parse(localStorage.getItem('rapidbook-simu-history')) || [],
				historyHeaders: [
					{ text: '', value: 'data-table-expand' },
					{ text: 'Date', value: 'date' },
					{ text: 'Pt de Livraison', value: 'orderPoints' },
					{ text: 'Prix livraison', value: 'prix_livraison' },
					{ text: 'Prix fabrication', value: 'prix_fabrication' },
					{ text: 'Prix emballage', value: 'prix_emballage' },
					{ text: 'Prix HT', value: 'prix_ht' },
					{ text: 'Prix TTC', value: 'prix_ttc' },
				],
				orderPoints: [],
				items: [
					{
						text: 'Rapidbook',
						disabled: false,
						to: '/',
					},
					{
						text: 'Dashboard',
						disabled: false,
						to: 'dashboard',
					},
					{
						text: 'Simulation Test',
						disabled: true,
						to: '',
					},
				],
			};
		},
		computed: {
			itemsPerPage() {
				switch (this.$vuetify.breakpoint.name) {
					case 'xl':
						return 4;
					case 'lg':
						return 3;
					case 'md':
						return 3;
					case 'sm':
						return 2;
					default:
						return 1;
				}
			},
			footerProps() {
				return {
					itemsPerPageOptions: [1, 2, 3, 4],
					showFirstLastPage: true,
				};
			},
			isCartSendable() {
				return this.orderPoints.filter((op) => op.lines.length > 0).length > 0;
			},
			orderPointsJSON() {
				return JSON.stringify(
					this.orderPoints.map((op) => {
						return {
							lines: op.lines.map((l) => {
								return {
									product: {
										page_count: l.product.page_count,
										bords_perdus: l.product.bords_perdus,
										category_id: l.product.category?.id,
										finition_id: l.product.finition?.id,
										impression_id: l.product.impression?.id,
										format_id: l.product.format?.id,
										height: l.product.height,
										width: l.product.width,
										papier_couverture_id: l.product.papier_couverture?.id,
										papier_interieur_id: l.product.papier_interieur?.id,
										pelliculage_id: l.product.pelliculage?.id,
										recto_verso: l.product.recto_verso,
									},
									quantity: l.quantity,
								};
							}),
							address: {
								address_type_id: op.address.address_type.id,
								country_id: op.address.country.id,
							},
						};
					})
				);
			},
		},
		watch: {
			products: {
				handler(newValue) {
					localStorage.setItem('rapidbook-simu-products', JSON.stringify(newValue));
				},
				deep: true,
			},
			history: {
				handler(newValue) {
					localStorage.setItem('rapidbook-simu-history', JSON.stringify(newValue));
				},
				deep: true,
			},
		},
		mounted() {},
		methods: {
			addProduct() {
				const productDefault = {
					bords_perdus: Math.random() < 0.5,
					category: null,
					dialog: true,
					enableCouverture: false,
					enableFormat: false,
					finition: null,
					format: null,
					height: null,
					impression: null,
					page_count: Math.floor(Math.random() * 400) * 2 + 2,
					papier_couverture: null,
					papier_interieur: null,
					pelliculage: null,
					recto_verso: Math.random() < 0.5,
					title: 'Produit n°' + parseInt(this.products.length + 1),
					uuid: nanoid(),
					valid: false,
					width: null,
				};
				this.products.push(productDefault);
			},
			addOrderPoint() {
				const orderPointDefault = {
					uuid: nanoid(),
					lines: [],
					address: {
						address_type: null,
						country: null,
					},
				};
				this.orderPoints.push(orderPointDefault);
			},
			calculatePrice() {
				if (!this.isCartSendable) return;

				this.loading = true;
				axios
					.post(
						process.env.VUE_APP_API_URL + '/simulation-test',
						{
							jsonData: this.orderPointsJSON,
						},
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						}
					)
					.then((r) => {
						this.prix_fabrication = r.data.prices.prix_fabrication;
						this.prix_livraison = r.data.prices.prix_livraison;
						this.prix_emballage = r.data.prices.prix_emballage;
						this.prix_ht = r.data.prices.prix_ht;
						this.prix_ttc = r.data.prices.prix_ttc;
						this.epaisseur = r.data.prices.epaisseur;
						this.poids = r.data.prices.poids;
						this.dialog = true;
					})
					.catch((e) => {})
					.finally(() => {
						this.loading = false;
					});
			},
			save() {
				this.dialog = false;
				this.showHistory = true;
				const historyItem = {
					orderPoints: this.orderPoints,
					prix_fabrication: this.prix_fabrication,
					prix_emballage: this.prix_emballage,
					prix_livraison: this.prix_livraison,
					prix_ht: this.prix_ht,
					prix_ttc: this.prix_ttc,
					date: new Date(),
					uuid: nanoid(),
				};

				this.history.push(structuredClone(historyItem));
				this.orderPoints = [];
				this.prix_fabrication = 0;
				this.prix_emballage = 0;
				this.prix_livraison = 0;
				this.prix_ht = 0;
				this.prix_ttc = 0;
			},
		},
	};
</script>

<style scoped lang="scss">
	.simulation {
		min-height: calc(100vh - 64px);
	}
</style>

<i18n lang="json">
{
	"fr": {
		"title": "Simulation Tarifaire"
	},
	"en": {
		"title": "Pricing Simulation"
	}
}
</i18n>
