<template>
	<v-dialog v-model="dialog" width="900" scrollable>
		<template #activator="{ on, attrs }">
			<v-lazy class="fill-height" transition="scale-transition">
				<v-hover v-slot="{ hover }">
					<v-card
						class="book-card"
						shaped
						v-bind="attrs"
						:style="'background-image: url(\'' + coverUrl + '\'); background-size: cover; background-position:right'"
						v-on="on"
					>
						<v-card-text :class="hover ? 'border-shaped book-text book-text-hover' : 'border-shaped book-text'">
							<div>
								<strong>{{ $t('product.title') }}</strong> : {{ product.title }}
							</div>
							<div>
								<strong>{{ $t('product.isbn') }}</strong> : {{ product.isbn }}
							</div>
							<div>
								<strong>{{ $t('product.author') }}</strong> :
								{{ product.author }}
							</div>
							<div>
								<strong>{{ $t('product.reference') }}</strong> :
								{{ product.reference }}
							</div>
							<div v-if="hover">
								<div v-if="product.finition !== null">
									<strong>{{ $t('product.finition') }}</strong> :
									{{ $i18n.locale === 'fr' ? product.finition.name : product.finition.en_name }}
								</div>
								<div>
									<strong>{{ $t('product.format') }}</strong> :
									{{ product.format.name }}
								</div>
								<div>
									<strong>{{ $t('product.pages') }}</strong> :
									{{ product.page_count }}
								</div>
							</div>
						</v-card-text>
						<v-card-actions>
							<v-btn color="" :class="hover ? 'border-shaped book-state book-state-hover' : 'border-shaped book-state'">
								<v-icon v-if="product.f1.id === 1">mdi-timer-sand</v-icon>
								<v-icon v-if="product.f1.id === 2">mdi-check</v-icon>
								<v-icon v-if="product.f1.id === 3">mdi-close</v-icon>
								<v-icon v-if="product.f1.id === 4">mdi-archive</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-hover>
			</v-lazy>
		</template>
		<v-card v-if="product" shaped elevation="24">
			<v-btn
				depressed
				class="border-shaped yellow--bg"
				style="position: absolute; top: 10px; right: 5px; z-index: 10"
				@click="dialog = !dialog"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-hover v-slot:="{ hover }">
				<v-img
					:src="coverUrl"
					:lazy-src="coverUrl"
					:class="hover ? 'img-detail img-detail-hover border-shaped' : 'img-detail border-shaped'"
				></v-img>
			</v-hover>
			<v-card-title class="text-h5 text-lg-h4 font-weight-bold">
				{{ product.title }}
			</v-card-title>
			<v-card-subtitle class="my-0 subtitle-2"
				><strong>{{ $t('product.status') }} </strong>:
				{{ $i18n.locale === 'fr' ? product.f1.name : product.f1.en_name }}
			</v-card-subtitle>
			<v-divider></v-divider>
			<v-card-text class="black--text">
				<v-alert
					type="warning"
					dense
					text
					class="border-shaped mt-2"
					v-if="product.impression.id === 2 && product.page_count % 4 !== 0"
				>
					{{ $t('product.message.print_color_page_count') }}
				</v-alert>
				<v-list>
					<div class="text-h4 font-weight-bold ml-n1 my-2">
						{{ $t('product.global') }}
					</div>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.id') }}</strong> : {{ product.uid }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.title') }}</strong> : {{ product.title }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.isbn') }}</strong> : {{ product.isbn }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.author') }}</strong> : {{ product.author }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.reference') }}</strong> : {{ product.reference }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.category') }}</strong> :
								{{ $i18n.locale === 'fr' ? product.category.name : product.category.en_name }}
								- {{ $t('order.price.tva') }} : {{ product.category.tva }}%</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item v-if="product.finition !== null"
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.finition') }}</strong> :
								{{ $i18n.locale === 'fr' ? product.finition.name : product.finition.en_name }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item v-if="product.acheve !== null"
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.acheve') }}</strong> :
								{{ $i18n.locale === 'fr' ? product.acheve.name : product.acheve.en_name }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item v-else
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.acheve') }}</strong> : {{ $t('acheve.0') }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.format') }}</strong> : {{ product.format.name }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.pages') }}</strong> : {{ product.page_count }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.thickness') }}</strong> :
								{{ Math.round(product.epaisseur * 2) / 2 }}
								mm</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.weight') }}</strong> :
								{{ Math.round(parseFloat(product.weight)) }}
								g</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item
						><v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.version') }}</strong> : {{ product.version }}</v-list-item-title
							></v-list-item-content
						></v-list-item
					>
					<v-list-item>
						<v-list-item-content
							><v-list-item-title
								><strong>{{ $t('global.created_at') }}</strong> :
								{{
									new Date(new Date(product.created_at).toLocaleString('en-US') + ' UTC').toLocaleString()
								}}</v-list-item-title
							></v-list-item-content
						>
					</v-list-item>
					<v-list-item>
						<v-list-item-content
							><v-list-item-title
								><strong>{{ $t('global.updated_at') }}</strong> :
								{{
									new Date(new Date(product.updated_at).toLocaleString('en-US') + ' UTC').toLocaleString()
								}}</v-list-item-title
							></v-list-item-content
						>
					</v-list-item>
					<v-list-item>
						<v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.owner') }}</strong> : {{ product.user.real_name }}</v-list-item-title
							></v-list-item-content
						>
					</v-list-item>
					<v-list-item>
						<v-list-item-content
							><v-list-item-title
								><strong>{{ $t('product.updated_by') }}</strong> :
								{{ product.last_modifier ? product.last_modifier.real_name : '' }}</v-list-item-title
							></v-list-item-content
						>
					</v-list-item>
				</v-list>
				<v-divider class="mt-2" v-if="product.papier_couverture_id"></v-divider>
				<v-list v-if="product.papier_couverture_id">
					<div class="text-h4 font-weight-bold ml-n1 my-2">
						{{ $t('product.cover') }}
					</div>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title
								><strong>{{ $t('product.pdf.cover') }}</strong></v-list-item-title
							>
						</v-list-item-content>
						<v-list-item-icon>
							<v-btn
								v-if="product.path_couverture"
								class="border-shaped yellow--bg mt-n3"
								depressed
								:loading="loading_couverture"
								@click="downloadPDF(product, 2)"
							>
								{{ $t('product.pdf.download') }}
							</v-btn>
							<div v-else class="font-italic">
								{{ $t('product.pdf.no_file') }}
							</div>
						</v-list-item-icon>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title
								><strong>{{ $t('product.paper') }}</strong> :
								{{ $i18n.locale === 'fr' ? product.papier_couverture.papier.name : product.papier_couverture.papier.en_name }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title
								><strong>{{ $t('product.lamination') }}</strong> :
								{{ $i18n.locale === 'fr' ? product.pelliculage.name : product.pelliculage.en_name }}</v-list-item-title
							>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<strong>{{ $t('product.recto_verso') }}</strong> :
								{{ product.is_couv_recto_verso ? $t('global.yes') : $t('global.no') }}</v-list-item-title
							>
						</v-list-item-content>
					</v-list-item>
					<v-expansion-panels flat accordion hover>
						<v-expansion-panel>
							<v-expansion-panel-header
								><strong>{{ $t('product.trimbox') }}</strong></v-expansion-panel-header
							>
							<v-expansion-panel-content>
								<ul>
									<li v-for="(value, k) in product.trim_box_couv" :key="k">
										<strong>{{ k }}</strong> : {{ value }}
									</li>
								</ul>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header
								><strong>{{ $t('product.mediabox') }}</strong></v-expansion-panel-header
							>
							<v-expansion-panel-content>
								<ul>
									<li v-for="(value, k) in product.media_box_couv" :key="k">
										<strong>{{ k }}</strong> : {{ value }}
									</li>
								</ul>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header
								><strong>{{ $t('product.bleedbox') }}</strong></v-expansion-panel-header
							>
							<v-expansion-panel-content>
								<ul>
									<li v-for="(value, k) in product.bleed_box_couv" :key="k">
										<strong>{{ k }}</strong> : {{ value }}
									</li>
								</ul>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header
								><strong>{{ $t('product.metadata') }}</strong></v-expansion-panel-header
							>
							<v-expansion-panel-content>
								<ul>
									<li v-for="(value, k) in product.meta_data_couv" :key="k">
										<strong>{{ k }}</strong> : {{ value }}
									</li>
								</ul>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-list>
				<v-divider class="mt-2"></v-divider>
				<v-list>
					<div class="text-h4 font-weight-bold ml-n1 my-2">
						{{ $t('product.inner') }}
					</div>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<strong>{{ $t('product.pdf.inner') }}</strong></v-list-item-title
							>
						</v-list-item-content>
						<v-list-item-icon>
							<v-btn
								v-if="product.path_interieur"
								class="border-shaped yellow--bg mt-n3"
								depressed
								:loading="loading_interieur"
								@click="downloadPDF(product, 1)"
							>
								{{ $t('product.pdf.download') }}
							</v-btn>
							<div v-else class="font-italic">
								{{ $t('product.pdf.no_file') }}
							</div>
						</v-list-item-icon>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<strong>{{ $t('product.paper') }}</strong> :
								{{ $i18n.locale === 'fr' ? product.papier_interieur.papier.name : product.papier_interieur.papier.en_name }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<strong>{{ $t('product.impression') }}</strong> :
								{{ $t('impression.' + product.impression.id) }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>
								<strong>{{ $t('product.lost_edges') }}</strong> :
								{{ product.is_bords_perdus ? $t('global.yes') : $t('global.no') }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-expansion-panels flat accordion hover>
					<v-expansion-panel>
						<v-expansion-panel-header
							><strong>{{ $t('product.trimbox') }}</strong></v-expansion-panel-header
						>
						<v-expansion-panel-content>
							<ul>
								<li v-for="(value, k) in product.trim_box_int" :key="k">
									<strong>{{ k }}</strong> : {{ value }}
								</li>
							</ul>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header
							><strong>{{ $t('product.mediabox') }}</strong></v-expansion-panel-header
						>
						<v-expansion-panel-content>
							<ul>
								<li v-for="(value, k) in product.media_box_int" :key="k">
									<strong>{{ k }}</strong> : {{ value }}
								</li>
							</ul>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header
							><strong>{{ $t('product.bleedbox') }}</strong></v-expansion-panel-header
						>
						<v-expansion-panel-content>
							<ul>
								<li v-for="(value, k) in product.bleed_box_int" :key="k">
									<strong>{{ k }}</strong> : {{ value }}
								</li>
							</ul>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header
							><strong>{{ $t('product.metadata') }}</strong></v-expansion-panel-header
						>
						<v-expansion-panel-content>
							<ul>
								<li v-for="(value, k) in product.meta_data_int" :key="k">
									<strong>{{ k }}</strong> : {{ value }}
								</li>
							</ul>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-list v-if="![null, 'null', ''].includes(product.comment)">
					<div class="text-h4 font-weight-bold ml-n1 my-2">
						{{ $t('product.trimbox') }}
					</div>
					{{ product.comment }}
				</v-list>
				<v-divider class="mt-2"></v-divider>
				<v-list v-if="productOrders.length > 0" dense>
					<div class="text-h4 font-weight-bold ml-n1 my-2">
						{{ $t('menu.orders') }}
					</div>
					<v-data-table :headers="headers" :items="productOrders" :items-per-page="5">
						<template #[`item.f1.name`]="{ item }">
							{{ $i18n.locale === 'fr' ? item.f1.name : item.f1.en_name }}
						</template>
						<template #[`item.lignes_commande`]="{ item }">
							<OrderDetail :id="item.id"></OrderDetail>
						</template>
						<template #[`item.prix_ttc`]="{ item }"> {{ parseFloat(item.prix_ttc).toFixed(2) }} € </template>
						<template #[`item.created_at`]="{ item }">
							<v-chip small>
								{{ new Date(item.created_at).toLocaleString() }}
							</v-chip>
						</template>
					</v-data-table>
				</v-list>
			</v-card-text>
			<v-card-actions v-if="product.f1.id !== 4">
				<ProductForm :product="product" :edit-mode="true" class="ml-auto"></ProductForm>
				<OrderProduct v-if="product.f1.id === 2 && !$store.getters.isChild" :product="product"></OrderProduct>
				<v-btn
					v-if="product.f1.id !== 2 && !$store.getters.isChild"
					large
					class="border-shaped ml-1"
					color="green"
					depressed
					disabled
				>
					<v-icon>mdi-cart-arrow-down</v-icon>
				</v-btn>
				<v-btn large class="border-shaped ml-1" color="red" depressed @click="deleteProduct(product.id)">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="js">
	import OrderProduct from '@/components/order/OrderProduct';
	import axios from 'axios';
	import OrderDetail from '@/components/order/OrderDetail';
	import ProductForm from '@/components/library/ProductForm';
	import { EventBus } from '@/utils';

	export default {
	    name: 'ProductDetail',
	    components: { ProductForm, OrderDetail, OrderProduct },
	    props: ['product'],
	    data() {
	        return {
	            dialog: null,
	            loading: true,
	            loading_interieur: false,
	            loading_couverture: false,
	            apiUrl: process.env.VUE_APP_API_URL,
	            coverUrl: '',
	        };
	    },
	    computed: {
	        headers: function () {
	            return [
	                { text: this.$t('order.id'), align: 'start', value: 'uid' },
	                { text: this.$t('order.status'), value: 'f1.name' },
	                { text: this.$t('order.price.ttc'), value: 'prix_ttc' },
	                { text: this.$t('order.line.quantity'), value: 'quantity' },
	                { text: this.$t('global.created_at'), value: 'created_at' },
	                { text: this.$t('global.action'), value: 'lignes_commande' },
	            ];
	        },
	        productOrders: function () {
	            if (!this.product) {
	                return [];
	            }

	            if (!this.product.lignes_commande) {
	                return [];
	            }
	            const map = {};
	            const orders = [];
	            this.product.lignes_commande.forEach((l) => {
	                if (l.enabled) {
	                    if (!map[JSON.stringify(l.commande)]) {
	                        map[JSON.stringify(l.commande)] = true;
	                        l.commande.quantity = l.quantity;
	                        orders.push(l.commande);
	                    } else {
	                        orders.find((o) => o.id === l.commande.id).quantity += l.quantity;
	                    }
	                }
	            });
	            return orders;
	        },
	        performanceNow: function () {
	            return performance.now();
	        },
	    },
	    mounted() {
	        this.getCoverUrl();
	    },
	    methods: {
	        getCoverUrl() {
	            axios
	                .get(this.apiUrl + '/product/' + this.product.id + '/vignette', {
	                    headers: {
	                        Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                    },
	                    responseType: 'blob',
	                })
	                .then((response) => {
	                    this.coverUrl = window.URL.createObjectURL(new Blob([response.data]));
	                });
	        },
	        downloadPDF(product, type) {
	            switch (type) {
	                case 1:
	                    this.loading_interieur = true;
	                    break;
	                case 2:
	                    this.loading_couverture = true;
	                    break;
	            }
	            axios
	                .get(process.env.VUE_APP_API_URL + '/product/' + product.id + '/pdf', {
	                    params: {
	                        type: type,
	                    },
	                    headers: {
	                        Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                    },
	                    responseType: 'blob',
	                })
	                .then((response) => {
	                    this.loading_interieur = false;
	                    this.loading_couverture = false;
	                    const url = window.URL.createObjectURL(new Blob([response.data]));
	                    const link = document.createElement('a');
	                    link.href = url;
	                    link.setAttribute('download', product.isbn + (type === 1 ? '_I' : '_C') + '.pdf'); // or any other extension
	                    document.body.appendChild(link);
	                    link.click();
	                })
	                .catch(() => {
	                    this.loading_interieur = false;
	                    this.loading_couverture = false;
	                    EventBus.$emit('notif', this.$t('error.product.pdf.download'), 'error');
	                });
	        },
	        deleteProduct(id) {
	            axios
	                .delete(process.env.VUE_APP_API_URL + '/product/' + id, {
	                    headers: {
	                        Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                    },
	                })
	                .then(() => {
	                    this.dialog = false;
	                    EventBus.$emit('refreshProducts');
	                    EventBus.$emit('refreshPanier');
	                });
	        },
	    },
	};
</script>

<style scoped lang="scss">
	.book {
		min-height: 50vh;

		.book-card {
			min-height: 100%;
		}

		.book-text {
			position: absolute;
			bottom: 0;
			background: var(--color-yellow);
			opacity: 0.85;
			height: 40%;
			transition: height 0.3s, opacity 0.3s;
		}

		.book-text-hover {
			height: 100%;
			opacity: 0.99;
			cursor: pointer;
		}

		.book-state {
			position: absolute;
			right: 5px;
			bottom: 5px;
		}
	}

	.img-detail {
		height: 150px;
		transition: height 0.3s;
	}

	.img-detail-hover {
		height: 39em;

		@media screen and (max-width: 600px) {
			height: 20em;
		}
	}
</style>
